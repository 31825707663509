import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
    {
        path: "/",
        name: "home",
        component: () => import("../views/LoginView.vue"),
    },
    {
        path: "/advertise",
        component: () => import("@/layouts/AdLayout"),
        children: [
            {
                path: "/sub/:id",
                name: "SubAdView",
                component: () => import("../views/FirstAdView.vue"),
                props: true,
            },
        ],
    },
    {
        path: "/admin",
        component: () => import("@/layouts/AdminLayout"),
        children: [
            {
                path: "settings",
                name: "AdminView",
                component: () => import("../views/AdminView.vue"),
                beforeEnter: async (to, from, next) => {
                    if (await checkToken()) {
                        next();
                    } else {
                        next("/?returnPath=settings");
                    }
                },
            },
        ],
    },
];

const checkToken = async () => {
    let rtn = false;

    try {
        if (localStorage.accessToken) {
            rtn = true;
        }
    } catch (err) {
        console.log("err", err);
    }

    return rtn;
};

const router = new VueRouter({
    // base: process.env.BASE_URL,
    routes,
});

export default router;
