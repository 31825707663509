import axios from "axios";

const state = () => ({
    accessToken: null,
});

const getters = {
    GET_ACCESS_TOKEN(state) {
        state.accessToken = state.accessToken || localStorage.accessToken;
        return state.accessToken;
    },
};

const mutations = {
    LOGIN(state, { token }) {
        state.accessToken = token;
        localStorage.accessToken = token;
    },
    LOGOUT(state) {
        state.accessToken = null;
        state.userId = null;
        delete localStorage.accessToken;
    },
};

const actions = {
    async LOGIN({ commit }, { email, password }) {
        console.log(process.env);
        return await axios
            .post(`${process.env.VUE_APP_AD_URI}/user/signin`, {
                email,
                password,
            })
            .then(({ data }) => {
                console.log("data", data);
                commit("LOGIN", data);
                axios.defaults.headers.common[
                    "Authorization"
                ] = `Bearer ${data.token}`;
            });
    },
    LOGOUT({ commit }) {
        axios.defaults.headers.common["Authorization"] = undefined;
        commit("LOGOUT");
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
